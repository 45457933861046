import { CloudUploadOutlined, FileExcelFilled, MergeFilled } from "@ant-design/icons"
import { Button, Checkbox, Flex, Select, Space, Tooltip, Upload } from "antd"
import { useContext, useEffect, useState } from "react"
import { AssetsContext } from "../../../context/AssetsContext"
import { useAssets } from "../../../hooks/useAssets"
import { DEFAULT_ASSET_LOCALE, DEFAULT_ASSET_VISUALIZATION_TYPE } from "../../../utils/enums"
import { ASSET_POSTIONS_COLUMNS, getWidth } from "../../../utils/reportsConfig"
import { colors, spaces } from "../../../utils/styles"
import { downloadExcelFile, tables } from "../../../utils/utils"
import { Loader } from "../../atoms/animations/Loader/Loader"
import { ShowDate } from "../../atoms/infos/ShowDate/ShowDate"
import { AssetLocaleSelect } from "../../atoms/select/AssetLocaleSelect/AssetLocaleSelect"
import { CustomerOrFundSelect, MODES } from "../../atoms/select/CustomerOrFundSelect/CustomerOrFundSelect"
import { VisualizationTypeSelect } from "../../atoms/select/VisualizationTypeSelect/VisualizationTypeSelect"
import { AssetsPositionTable } from "../../molecules/tables/AssetsPositionTable/AssetsPositionTable"
import { GlobalContext } from "../../../context/GlobalContext"
import useNotification from "antd/es/notification/useNotification"

export const AssetsPosition = () => {
  const [forceReload, setForceReload] = useState(0)
  const [visualizationType, setVisualizationType] = useState(DEFAULT_ASSET_VISUALIZATION_TYPE)
  const [customerSelectedId, setCustomerSelectedId] = useState(null)
  const [locale, setLocale] = useState(DEFAULT_ASSET_LOCALE)
  const [hideEmptyClasses, setHideEmptyClasses] = useState(true)
  const [isToViewExploded, setIsToViewExploded] = useState(false)
  const [customers, setCustomers] = useState([])
  const [assetGraph, setAssetGraph] = useState([])
  const [lastUpdate, setLastUpdate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dimBase, setDimBase] = useState(tables.DIM_CUSTOMER)

  const [isToExpandAll, setIsToExpandAll] = useState(false)
  const [isToShowOnlyAssets, setIsToShowOnlyAssets] = useState(false)

  const { session, supabase } = useContext(GlobalContext)

  const [api, contextHolder] = useNotification()

  const [data, setData] = useState([])

  const { getRecentPositions, getRecentPositionsAsExcelFormat } = useAssets({
    customerSelectedId,
    visualizationType,
    locale,
    hideEmptyClasses,
    dimBase,
    isToViewExploded,
  })

  useEffect(() => {
    setCustomerSelectedId(null)
    setVisualizationType(DEFAULT_ASSET_VISUALIZATION_TYPE)
    setLocale(DEFAULT_ASSET_LOCALE)
    setHideEmptyClasses(true)
    setIsToExpandAll(false)
    setAssetGraph([])
  }, [dimBase])

  useEffect(() => {
    setIsToExpandAll(false)
    setIsLoading(true)
    getRecentPositions()
      .then((result) => {
        setData(result.data)
        setCustomers(result.customers)
        setIsLoading(false)
        setLastUpdate(result.lastUpdate)
        setAssetGraph(result.assetGraph)
      })
      .catch((error) => {
        console.error(error)
        setForceReload(forceReload + 1)
      })
  }, [customerSelectedId, visualizationType, locale, hideEmptyClasses, forceReload, isToViewExploded, dimBase])

  const handleExport = async () => {
    const data = await getRecentPositionsAsExcelFormat()
    const columnsAvailable = Array.from(new Set(data.map((item) => Object.keys(item)).flat()))
    const columns = columnsAvailable.map((column) => ({ name: column, width: getWidth(ASSET_POSTIONS_COLUMNS, column) }))

    let filename = "POSICAO_DE_ATIVOS_"

    if (customerSelectedId && dimBase === tables.DIM_CUSTOMER) filename += `${data[0].Cliente.replaceAll(" ", "_")}_`
    else if (customerSelectedId && dimBase !== tables.DIM_CUSTOMER) filename += `${data[0].Fundo.replaceAll(" ", "_")}_`
    else if (!customerSelectedId && dimBase === tables.DIM_CUSTOMER) filename += "CLIENTES_"
    else if (!customerSelectedId && dimBase !== tables.DIM_CUSTOMER) filename += "FUNDOS_"

    filename += `${data[0].Data}.xlsx`

    downloadExcelFile(data, "Posição de Ativos", columns, filename)
  }

  const uploadPositions = async (file) => {
    const yyyymmdd = /^\d{4}-\d{2}-\d{2}\.txt$/

    if (!yyyymmdd.test(file.name)) {
      api.error({
        message: "Nome do arquivo inválido",
        description: "O nome do arquivo deve seguir o padrão YYYY-MM-DD.txt da data desejada",
      })

      return false
    }
    
    api.info({ message: "Enviando arquivo", description: "Aguarde enquanto o arquivo é processado" })

    await supabase.auth.refreshSession()

    return true
  }

  const checkUpload = (info) => {
    const { status, response } = info.file
    if (status === "done") api.success({ message: "Arquivo enviado com sucesso", description: response })
    else if (status === "error") api.error({ message: "Erro ao enviar arquivo", description: response || "Erro desconhecido" })
  }

  return isLoading ? (
    <Loader />
  ) : (
    <AssetsContext.Provider
      value={{
        data,
        customers,
        lastUpdate,
        visualizationType,
        setVisualizationType,
        customerSelectedId,
        setCustomerSelectedId,
        locale,
        setLocale,
        hideEmptyClasses,
        setHideEmptyClasses,
        assetGraph,
        isLoading,
        setIsLoading,
        setForceReload: () => setForceReload(forceReload + 1),
      }}
    >
      {contextHolder}
      <Flex gap={spaces.space1} style={{ width: "max-content", height: "100%", paddingLeft: spaces.space2, paddingRight: spaces.space2 }} vertical>
        <Flex gap={spaces.space1} style={{ padding: spaces.space1 }} vertical>
          <Space align="end" style={{ alignItems: "center", gap: spaces.space2 }}>
            <VisualizationTypeSelect />
            <CustomerOrFundSelect
              mode={dimBase === tables.DIM_CUSTOMER ? MODES.CUSTOMER : MODES.FUND}
              value={customerSelectedId}
              setValue={setCustomerSelectedId}
            />
            <AssetLocaleSelect locale={locale} setLocale={setLocale} />
            <Space>
              <b>Posições:</b>
              <Select value={dimBase} onChange={(value) => setDimBase(value)} style={{ width: 150 }}>
                <Select.Option value={tables.DIM_CUSTOMER}>Clientes</Select.Option>
                <Select.Option value={tables.DIM_TERA_FUND}>Fundos TERA</Select.Option>
              </Select>
            </Space>
          </Space>
          <Space style={{ alignItems: "center", gap: spaces.space3 }}>
            <Checkbox checked={hideEmptyClasses} onChange={(e) => setHideEmptyClasses(e.target.checked)} disabled={isToShowOnlyAssets}>
              Ocultar classes vazias
            </Checkbox>
            <Checkbox checked={isToShowOnlyAssets} onChange={(e) => setIsToShowOnlyAssets(e.target.checked)}>
              Mostrar somente ativos
            </Checkbox>
            <Tooltip title="Faz a explosão das posições através dos fundos TERA/VRB">
              <Checkbox checked={isToViewExploded} onChange={(e) => setIsToViewExploded(e.target.checked)}>
                Visão explodida
              </Checkbox>
            </Tooltip>
            <Button onClick={() => setIsToExpandAll(!isToExpandAll)} disabled={isToShowOnlyAssets} type="primary" icon={<MergeFilled />}>
              {isToExpandAll ? "Recolher tudo" : "Expandir tudo"}
            </Button>
            <Tooltip title="A exportação irá trazer a lista de ativos e classes como colunas, de acordo com os filtros selecionados.">
              <Button type="primary" style={{ backgroundColor: colors.green }} icon={<FileExcelFilled />} onClick={handleExport}>
                Exportar
              </Button>
            </Tooltip>
            <Upload
              showUploadList={false}
              accept=".txt"
              beforeUpload={uploadPositions}
              action={process.env.REACT_APP_LOTE45_REPORT_UPLOAD_URL}
              headers={{ Authorization: `Bearer ${session.access_token}` }}
              onChange={checkUpload}
            >
              <Tooltip title="Anexe o relatório All Trading Desks Overview do Lote45 para atualizar a data de referência. O nome do arquivo deve seguir o padrão YYYY-MM-DD.txt da data desejada">
                <Button type="primary" style={{ backgroundColor: "black" }} icon={<CloudUploadOutlined />}>
                  Atualizar posições
                </Button>
              </Tooltip>
            </Upload>
            <ShowDate date={lastUpdate} title="Data de Referência" />
          </Space>
        </Flex>
        <Space style={{ alignItems: "baseline" }}>
          <AssetsPositionTable
            columnWidth={dimBase === tables.DIM_CUSTOMER ? 150 : 200}
            expandAll={isToExpandAll}
            showOnlyAssets={isToShowOnlyAssets}
            enableSort={customerSelectedId}
            data-hj-suppress
          />
        </Space>
      </Flex>
    </AssetsContext.Provider>
  )
}
