import { createClient } from "@supabase/supabase-js"
import { useEffect, useState } from "react"
import { HashRouter as Router } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext"
import { tables } from "../../utils/utils"
import Home from "./Home"
import Login from "./Login"

export const App = () => {
  const [supabase, setSupabase] = useState(null)
  const [session, setSession] = useState(null)
  const [classes, setClasses] = useState([])
  const [customerOrFundSelected, setCustomerOrFundSelected] = useState(null)

  useEffect(() => setSupabase(createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY)), [])
  useEffect(() => {
    if (!supabase) return () => {}

    supabase.auth.getSession().then(({ data: { session } }) => setSession(session))

    supabase
      .from(tables.DIM_ASSET_CLASS)
      .select("id, name, id_parent")
      .not("id", "eq", 74)
      .order("name")
      .then(({ data }) => setClasses(data || []))
  }, [supabase])

  return (
    <Router>
      <GlobalContext.Provider value={{ supabase, session, classes, customerOrFundSelected, setCustomerOrFundSelected }}>
        {supabase && !session && <Login />}
        {supabase && session && <Home />}
      </GlobalContext.Provider>
    </Router>
  )
}
